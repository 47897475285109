html {
  overflow-y: scroll;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: black !important;
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}

a:active {
  text-decoration: none !important;
}


.nav-link.active, .nav-pills .show>.nav-link, .dropdown-item.active{
  color: #fff !important;
  background-color: #da9263 !important;
}

.nav-link, .dropdown-item{
  transition: ease-in 0.3s;
  -webkit-transition: ease-in 0.3s;
}

.nav-link:hover, .dropdown-item:hover{
  color: #fff !important;
  background-color: #da93637e !important;
  transition: ease-in 0.3s;
  -webkit-transition: ease-in 0.3s;
}

.order-background{
  background-color: #F8EFEF;
}

.button-lg{
  background-color: #746C68 !important;
}

.about-background{
  background-color: #F8F8F8;
}

.footer{
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;  
}

.home-img-cover{
  object-fit: cover;
  height: 100vh !important;
  width: 100%;
}

#home-img-small-1{
  object-fit: cover;
  height: 50vh !important;
  width: 100%;
}
#home-img-small-2{
  object-fit: cover;
  margin-top: 2vh;
  height: 48vh !important;
  width: 100%;
}

#orderThanks{
  margin: 10px;
}

.isEmailError{
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + .75rem) !important;
  background-color: #ff40402b !important;
  background-image: none !important;
  background-repeat: no-repeat !important;
  background-position: right calc(.375em + .1875rem) center !important;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem) !important;
}

@media screen and (max-width: 767px) {   
  .home-img-cover{
    object-fit: cover;
    height: 40vh !important;
    width: 100%;
    margin-bottom: 2vh;
  }

  #about-description{
    margin-top: 3vh;
  }

  #icon{
    width: 40px;
    height: 40px;
  }
}